import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../Components/Header";
import FullCalendar from "@fullcalendar/react";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import DailyContent from "../Components/DailyContent";
import axios from "axios";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import moment from "moment";
import { isAuthenticated } from "../Services/auth";

function Rooms() {
  const [apiResponse, setApiResponse] = useState([]);
  const [blockResponse, setBlockResponse] = useState([]);
  const navigate = useNavigate();

  const now = new Date();

  const newMoment = moment;
  newMoment.locale("pt-br");

  const startDate = moment().subtract(1, "days").toDate();
  const endDate = moment().add(1, "days").toDate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login");
    }
  }, []);

  /* A variable that is being used to make a request to the API. */
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}api/appointments`,
    params: {
      today_start: startDate,
      today_end: endDate,
    },
    headers: {},
  };

  var configBlock = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}api/blocks`,
  };

  useEffect(() => {
    const getBlock = async () => {
      const data = await axios(configBlock);
      setBlockResponse(data.data.blocks);
    };
    getBlock();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const data = await axios(config);
      setApiResponse(data.data.appointments);
    };
    getData();
  }, []);

  /* Mapping the response from the API to a new array. */

  let color = null;
  const events = apiResponse.map((appointment) => {
    if (appointment.status === "Aguardando início") {
      color = "#d59f00";
    } else if (appointment.status === "Iniciado") {
      color = "#339900";
    } else {
      color = "#dc3545";
    }
    return {
      doctor: appointment.surgeon,
      status: appointment.status,
      contamination: appointment.observation,
      procedure: appointment.procedure,
      patient: appointment.patient,
      obse: appointment.observation,
      id: appointment.id,
      block: appointment.block.name,
      blockId: appointment.block.id,
      starts_at: appointment.starts_at,
      start: appointment.starts_at,
      end: appointment.ends_at,
      blocks: blockResponse,
      color: color,
    };
  });

  let block1 = [],
    block2 = [],
    block3 = [],
    block4 = [],
    block5 = [],
    block6 = [];

  events.forEach((event) => {
    if (event.block === "Sala 1") {
      block1.push(event);
    } else if (event.block === "Sala 2") {
      block2.push(event);
    } else if (event.block === "Sala 3") {
      block3.push(event);
    } else if (event.block === "Sala 4") {
      block4.push(event);
    } else if (event.block === "Sala 5") {
      block5.push(event);
    } else if (event.block === "Sala 6") {
      block6.push(event);
    }
  });

  /* Setting the time to the current time. */
  let hour = now.getHours();
  // let hour = 20;
  let period;
  if(hour < 4 && hour > 0){
    period = "00:00:00";
  } else if (hour >= 4 && hour < 8){
    period = "04:00:00";
  } else if (hour >= 8 && hour < 12){
    period = "08:00:00";
  } else if (hour >= 12 && hour < 16){
    period = "12:00:00";
  } else if (hour >= 16 && hour < 20){
    period = "16:00:00";
  } else {
    period = "20:00:00"
  }
 

  /**
   * After t milliseconds, reload the page.
   * @param t - The time in milliseconds to wait before refreshing the page.
   */
  function AutoRefresh(t) {
    setTimeout("location.reload(1);", t);
  }
  return (
    <div>
      <Header />
      <Container onLoad={AutoRefresh(100000)}>
        <Row>
          <Col id="block-1">
            <div
              id="room-num"
              className="fw-bold fs-2-r text-white bg-primary p-2 rounded"
            >
              Sala 1
            </div>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, bootstrap5Plugin]}
              contentHeight={730}
              headerToolbar={false}
              scrollTime={period}
              allDaySlot={false}
              initialView="timeGridDay"
              locale={ptBrLocale}
              // eventColor={"rgba(var(--bs-primary-rgb))"}
              eventContent={DailyContent}
              events={block1}
            />
          </Col>
          <Col id="block-2">
            <div
              id="room-num"
              className="fw-bold fs-2-r text-white bg-room p-2 rounded"
            >
              Sala 2
            </div>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, bootstrap5Plugin]}
              contentHeight={730}
              headerToolbar={false}
              scrollTime={period}
              allDaySlot={false}
              initialView="timeGridDay"
              eventColor={"#339900"}
              locale={ptBrLocale}
              eventContent={DailyContent}
              events={block2}
            />
          </Col>
          <Col id="block-3">
            <div
              id="room-num"
              className="fw-bold fs-2-r text-white bg-danger p-2 rounded"
            >
              Sala 3
            </div>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, bootstrap5Plugin]}
              contentHeight={730}
              headerToolbar={false}
              scrollTime={period}
              allDaySlot={false}
              initialView="timeGridDay"
              locale={ptBrLocale}
              eventColor={"rgba(var(--bs-danger-rgb))"}
              eventContent={DailyContent}
              events={block3}
            />
          </Col>
          <Col id="block-4">
            <div
              id="room-num"
              className="fw-bold fs-2-r text-white bg-success p-2 rounded"
            >
              Sala 4
            </div>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, bootstrap5Plugin]}
              contentHeight={730}
              headerToolbar={false}
              scrollTime={period}
              allDaySlot={false}
              initialView="timeGridDay"
              eventColor={"rgba(var(--bs-success-rgb))"}
              locale={ptBrLocale}
              eventContent={DailyContent}
              events={block4}
            />
          </Col>
          <Col id="block-5">
            <div
              id="room-num"
              className="fw-bold fs-2-r text-white bg-secondary p-2 rounded"
            >
              Sala 5
            </div>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, bootstrap5Plugin]}
              contentHeight={730}
              headerToolbar={false}
              scrollTime={period}
              allDaySlot={false}
              eventColor={"rgba(var(--bs-secondary-rgb))"}
              initialView="timeGridDay"
              locale={ptBrLocale}
              eventContent={DailyContent}
              events={block5}
            />
          </Col>
          <Col id="block-6">
            <div
              id="room-num"
              className="fw-bold fs-2-r text-white bg-room-6 p-2 rounded"
            >
              Sala 6
            </div>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, bootstrap5Plugin]}
              contentHeight={730}
              headerToolbar={false}
              scrollTime={period}
              allDaySlot={false}
              eventColor={"#663300"}
              initialView="timeGridDay"
              locale={ptBrLocale}
              eventContent={DailyContent}
              events={block6}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

// function Rooms({ title }) {
//     return <Container>
//         <Row className="m-5">
//             {
//                 title ? (
//                     <h1>Salas de Cirurgia</h1>
//                 ) : ''
//             }
//         </Row>

//         <Row className="justify-content-center">
//             <Col lg={10}>
//                 <Row className="my-lg-4">
//                     <Col lg={6} className="my-4 my-lg-0">
//                         <Link to="/sala-1/semana" state={{
//                             path: 'sala-1',
//                             room: 'Sala 1',
//                             choosenDate: undefined,
//                         }}>
//                             <RoomCard
//                                 room="Sala 1"
//                                 color="primary"
//                             />
//                         </Link>
//                     </Col>
//                     <Col lg={6} className="my-4 my-lg-0">
//                         <Link to="/sala-2/semana" state={{
//                             path: 'sala-2',
//                             room: 'Sala 2',
//                             choosenDate: undefined,
//                         }}>
//                             <RoomCard
//                                 room="Sala 2"
//                                 color="warning"
//                             />
//                         </Link>
//                     </Col>
//                 </Row>
//                 <Row>
//                     <Col lg={6} className="my-4 my-lg-0">
//                         <Link to='/sala-3/semana' state={{
//                             path: 'sala-3', room: 'Sala 3',
//                             choosenDate: undefined
//                         }}>
//                             <RoomCard
//                                 room="Sala 3"
//                                 color="danger"
//                             />
//                         </Link>
//                     </Col>
//                     <Col lg={6} className="my-4 my-lg-0">
//                         <Link to='/sala-4/semana' state={{
//                             path: 'sala-4', room: 'Sala 4',
//                             choosenDate: undefined
//                         }}>
//                             <RoomCard
//                                 room="Sala 4"
//                                 color="success"
//                             />
//                         </Link>
//                     </Col>
//                 </Row>
//             </Col>
//         </Row>
//     </Container>;
// }

export default Rooms;
