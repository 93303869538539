import React, { useState, useEffect } from "react";
import LoginImg from "../assets/login.jpg";
import LogoImg from "../assets/logo.png";
import { login, isAuthenticated } from "../Services/auth";
import api from "../Services/api";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function Login() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/");
    }
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    await api
      .post("login", {
        email,
        password,
      })
      .then((response) => {
        login(response.data.data.token);
        navigate("/");
      })
      .catch((error) => {
        console.log(error.response.data.message);
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }

  return (
      <div className="row m-0 w-100 h-100">
        <div className="col-5">
          <div className="login-form mt-5 mb-5">
            <div className="auth-logo">
              <img src={LogoImg} alt="" />
            </div>
            <p className="m-5 auth-subtitle">
              Faça login com seus dados cadastrais.
            </p>
            <form onSubmit={handleSubmit} className="d-flex flex-column">
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Digite seu e-mail"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor="floatingInput">E-mail</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label htmlFor="floatingPassword">Senha</label>
              </div>

              <button className="btn-ibiapaba shadow-lg mt-5" type="submit">
                Entrar
              </button>
            </form>
          </div>
        </div>
        <div className="col-7 bg-ibiapaba"></div>
      </div>
  );
}
