import React, { useState, useEffect } from "react";
import { Container, Image, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Route, Link, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../Services/auth";
import Logo from "../assets/logo.png";
import moment from "moment";

function Header() {
  const location = useLocation();
  const url = window.location.pathname.split("/").pop();
  const days = [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sabado",
  ];
  const month = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  const today = new Date();
  const date= moment().format('DD/MM/YYYY');
  
  const curTime =
    today.getHours() +
    ":" +
    (today.getMinutes() < 10 ? "0" : "") +
    today.getMinutes();
  const curDay = today.getDate();
  const curWeekDay = days[today.getDay()];
  const curMonth = month[today.getMonth()];
  const navigate = useNavigate();
  function handleLogout() {
    logout();
    navigate("/login");
  }

  return (
    <Navbar bg="light" expand="lg" className="header-navbar">
      <Container>
        <Navbar.Brand>
          <Link to="/salas">
            <Image src={Logo} className="header-logo" />{" "}
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
          <Nav className="menu-links d-flex gap-4 align-items-center">
            <div className="d-flex align-items-center gap-5">
              <div className="today">
                <span className="today-day">{date}</span>
                {/* <span className="today-week"> {date}</span> */}
                {/* <span className="today-month"> / {curMonth}</span> */}
              </div>
              <div id="hours">
                <strong className="fw-bold fs-4">{curTime}</strong>
              </div>
              <div className="d-flex align-items-center gap-2">
              <div className="status-1">
                  <small>Aguardando</small>
                </div>
              <div id="legend" className="d-flex flex-column gap-1">
                <div className="status-2">
                  <small>Iniciado</small>
                </div>
                <div className="status-3">
                  <small>Finalizado</small>
                </div>
              </div>
              </div>
              
            </div>
            <button className="btn-ibiapaba fs-5" onClick={handleLogout} role='button'>
              <i className="bi bi-box-arrow-right"></i>
            </button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
