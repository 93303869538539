import React from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap";
import { Popover, OverlayTrigger } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";

function DailyContent(events) {
  let option = `${events.event.extendedProps.blockId}`;
  let start_at = ``;
  let procedure_time = null;
  let date = new Date(events.event.extendedProps.starts_at);
  const blocks = events.event.extendedProps.blocks;
  const result = [];
  const appointmentId = events.event.id;
  blocks.forEach((block, index) => {
    result.push(
      <option value={block.id} key={index}>
        {block.name}
      </option>
    );
  });

  const handleOption = (e) => {
    option = e.target.value;
  };

  const handleTime = (e) => {
    start_at = e.target.value;
  };

  const handleDuration = (e) => {
    procedure_time = e.target.value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let res = await axios.put(
      `${process.env.REACT_APP_API_URL}api/appointments/${events.event.id}/update-block`,
      {
        block: option,
        start_at: start_at,
        procedure_time: procedure_time,
      }
    );
    if (res.data.data === 1) {
      window.alert("Existe uma cirurgia nessa sala durante esse horario!");
      window.location.reload();
    } else {
      window.location.reload();
    }
  };

  const deleteAppointment = async (e) => {
    e.preventDefault();
    Swal.fire({
      icon: "warning",
      title: "Você tem certeza?",
      text: "Essa ação não poderá ser revertida!",
      showCancelButton: true,
      confirmButtonText: "Sim, excluir item",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${process.env.REACT_APP_API_URL}api/appointments/${events.event.id}/delete`
          )
          .then((result) => {
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const startAppointment = async (e) => {
    e.preventDefault();
    const start = await axios
      .get(
        `${process.env.REACT_APP_API_URL}api/appointments/${events.event.id}/start`
      )
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getData = () => {
    const blocks = axios
      .get(`${process.env.REACT_APP_API_URL}api/blocks/`)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  };

  const popover = (
    <Popover
      {...(events.event.extendedProps.block === "Sala 1"
        ? { id: "popover-1" }
        : { id: "popover-edit" })}
    >
      <Popover.Header>
        <div className="d-flex justify-content-between">
          Gerenciar
          <div className="d-flex gap-1">
            {events.event.extendedProps.status === "Aguardando início" ? (
              <button
                className="btn btn-sm btn-success"
                value={appointmentId}
                onClick={startAppointment}
              >
                <i className="bi bi-play-btn"></i>
              </button>
            ) : events.event.extendedProps.status === "Iniciado" ? (
              <button
                className="btn btn-sm btn-danger"
                value={appointmentId}
                onClick={startAppointment}
              >
                <i className="bi bi-stop-btn"></i>
              </button>
            ) : (
              ""
            )}
            <button
              className="btn btn-sm btn-danger"
              value={appointmentId}
              onClick={deleteAppointment}
            >
              <i className="bi bi-trash3"></i>
            </button>
          </div>
        </div>
      </Popover.Header>
      <Popover.Body>
        <Form className="d-flex flex-column" onSubmit={handleSubmit}>
          <FloatingLabel controlId="floatingSelect" label="Selecione a sala">
            <Form.Select
              aria-label="Floating label select example"
              onChange={handleOption}
              defaultValue={events.event.extendedProps.blockId}
            >
              {result}
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInputGrid"
            label="Horário de início"
          >
            <Form.Control
              type="datetime-local"
              onChange={handleTime}
              min={start_at}
            />
          </FloatingLabel>
          <FloatingLabel
            label="Duração da cirurgia"
            controlId="floatingInputGrid"
          >
            <Form.Control type="time" onChange={handleDuration} />
          </FloatingLabel>
          <Button
            variant="success"
            className="align-self-center mt-2"
            type="submit"
          >
            {" "}
            Atualizar
          </Button>
        </Form>
      </Popover.Body>
    </Popover>
  );

  const eventData = (
    <Popover {...(events.event.extendedProps.block === "Sala 6"
    ? { id: "popover-6" }
    : { id: "popover-basic" })} >
      <Popover.Header>
        <div className="d-flex justify-content-between">Detalhes</div>
      </Popover.Header>
      <Popover.Body>
        <div className="d-flex justify-content-center">
          <span className="fw-bold align-self-center fs-6 text-center mb-2">
            {events.timeText}
          </span>
        </div>
        <div className="d-flex flex-column gap-3">
          <span className="fw-bold">{events.event.extendedProps.doctor}</span>
          <span className="text-start">
            Status:{" "}
            <span className="fw-bold">{events.event.extendedProps.status}</span>
          </span>
          <span className="text-start">
            Paciente:{" "}
            <span className="fw-bold">
              {events.event.extendedProps.patient}
            </span>
          </span>
          <span className="text-start">
            Tipo:{" "}
            <span className="fw-bold">
              {events.event.extendedProps.procedure}
            </span>
          </span>
          <span className="text-start">
            Observação:{" "}
            <span className="fw-bold">{events.event.extendedProps.obse}</span>
          </span>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <div id="dailyContent">
      <div id="header" className="d-flex justify-content-evenly mb-2">
        
          <OverlayTrigger
            trigger="click"
            rootClose
            {...(events.event.extendedProps.block === "Sala 1"
            ? { placement: "right" }
            : { placement: "left" })}
            overlay={popover}
            onClick={getData}
          >
            <button className="btn btn-sm btn-outline-light">
              {" "}
              <i className="bi bi-pencil"></i>
            </button>
          </OverlayTrigger>
        <OverlayTrigger
          trigger="click"
          rootClose
          {...(events.event.extendedProps.block === "Sala 6"
            ? { placement: "left" }
            : { placement: "right" })}
          overlay={eventData}
          onClick={getData}
        >
          <button className="btn btn-sm btn-outline-light">
            <i className="bi bi-calendar"></i>
          </button>
        </OverlayTrigger>
      </div>
      <span className="fw-bold text-center fs-6 " id="event-custom-time">
        {events.timeText}
      </span>
      <div className="d-flex gap-2 mt-2">
        <div className="daily-div d-flex flex-column align-items-start gap-2 fs-6">
          <span className="text-start fw-bold">
            {events.event.extendedProps.doctor}
          </span>
          <span className="text-start">
            Status:{" "}
            <span className="fw-bold">{events.event.extendedProps.status}</span>
          </span>
          {/* <span className="text-start">
            Paciente:{" "}
            <span className="fw-bold">
              {events.event.extendedProps.patient}
            </span>
          </span>
          <span className="text-start">
            Tipo:{" "}
            <span className="fw-bold">
              {events.event.extendedProps.procedure}
            </span>
          </span> */}
          {/* <span className="text-start">Observação: <span
                            className="fw-bold">{events.event.extendedProps.obse}</span></span> */}
        </div>
      </div>
    </div>
  );
}

export default DailyContent;
