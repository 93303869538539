import './App.css';
import '../src/assets/custom.css';
import { Routes, Route, Link } from "react-router-dom";
import Rooms from './Pages/Rooms';
import Header from './Components/Header';
import Login from './Pages/Login';

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <Routes>
          <Route path="login" element={<Login />} />
          <Route path="/" element={<Rooms title={true} />} />
          {/* <Route path="salas" element={<Rooms title={true} />} /> */}
          

       
      </Routes>
    </div>
  );
}

export default App;
